export const activities = [
  {
    id: "6",
    title: "Summer School Flutter",
    place: "INSAT",
    date: "10/11 Septembre 2021",
    description:
      "During this workshop, the trainers introduced some fundamental concepts needed for flutter then they taught the participants to develop some app pages, connect them, style the buttons and the text to end with making the logic which collects the answers either wrong or right ones.\n",
    details: "Summer School",
    images: [
      "summerSchool/flutter/image1.jpg",
      "summerSchool/flutter/image2.jpg",
      "summerSchool/flutter/image3.jpg",
      "summerSchool/flutter/image4.jpg",
      "summerSchool/flutter/image5.jpg",
    ],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
  {
    id: "5",
    title: "Summer School CP",
    place: "INSAT",
    date: "11/12 Septembre 2021",
    description:
      "During this workshop, the trainer talked about the history of competitive programming, the interest in such competitions. Then he introduced the notion of applications' complexity and the rest of the workshop was dedicated to solving problems.      \n",
    details: "Summer School",
    images: [
      "summerSchool/CP/image1.jpg",
      "summerSchool/CP/image2.jpg",
      "summerSchool/CP/image3.jpg",
      "summerSchool/CP/image4.jpg",
      "summerSchool/CP/image5.jpg",
    ],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
  {
    id: "4",
    title: "IEEE Extrem 15",
    place: "INSAT",
    date: "22/23 Octobre 2021",
    description:
      "IEEEXtreme 15.0 INSAT edition took place in INSAT with our theme being the Olympics. Our opening ceremony started with a word from the committee and was enclosed by a performance from a local band. Afterwards, the participants went to the competition space, had dinner and the 24h contest began.All along the journey they played games, ate a lot, watched a movie and slept too. In the feedback form, we found highly satisfied participants and that was our goal.\n",
    details: "online industry AI formation",
    images: [
      "xtreme/image5.jpg",
      "xtreme/image1.jpg",
      "xtreme/image3.jpg",
      "xtreme/image4.jpg",
    ],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
  {
    id: "1",
    title: "Advancing Through AI",
    place: "INSAT",
    date: "24 November 2021",
    description:
      'During the IEEE Region8 Cross-Sectional Student Initiatives Booster Program hosted by our IEEE INSAT student branch, our chapter held the "Advancing IoT through AI" workshop where Mouafek Ayedi explained some concepts about IoT, AI, and how the two can be connected..\n',
    details: "online industry AI formation",
    images: ["atai/photo.jpg"],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
  {
    id: "2",
    title: "WebDev Workshop WEEK 1",
    place: "INSAT",
    date: "13 November 2021",
    description:
      "We held our first series of workshops for web development where we introduced some basic concepts for front-end and back-end development and where we had Oussema Ghorbel tutoring our participants about some computer networks fundamentals. We ended our sessions with an introduction to Git and HTML and left the participants with homework for next session. We also made sure they met each other and bonded over the break between sessions.  \n",
    details: "webdev workshop",
    images: [
      "webdev/week1/image5.jpg",
      "webdev/week1/image1.jpg",
      "webdev/week1/image2.jpg",
      "webdev/week1/image3.jpg",
      "webdev/week1/image4.jpg",
    ],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
  {
    id: "3",
    title: "WebDev Workshop WEEK 2",
    place: "INSAT",
    date: "17 November 2021",
    description:
      "In the second session of our web development series our topic was css, our webmaster along with a whole team assured this workshop and helped all participants. The main points were : what is css, how does it work and the concept of classes and ids. The trainers explained also css selectors and properties. Finally, they tested the participants' comprehension of the concepts by a kahoot quizz where the fun, competition and learning were all mixed together.      \n",
    details: "webdev workshop",
    images: [
      "webdev/week2/image6.jpg",
      "webdev/week2/image5.jpg",
      "webdev/week2/image1.jpg",
      "webdev/week2/image3.jpg",
      "webdev/week2/image2.jpg",
      "webdev/week2/image4.jpg",
    ],
    facebookUrl: "https://www.facebook.com/IeeeInsatStudentBranch",
    instagramUrl: "https://www.instagram.com/ieee.insat.sb/",
  },
];
